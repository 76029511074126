<ts-dropdown [content]="dropdownTemplate" *ngIf="user$ | async as user" #tsDropdown>

  <button
    type="button"
    class="nav-icon-button-with-text"
    [ngClass]="{ 'ts-avatar-container': user.image }"
  >
    <span class="ts-icon ts-icon-users-user" *ngIf="!user.image"></span>
    <div *ngIf="user.image" class="ts-avatar">
      <img [src]="user.image" alt="avatar" width="100%" height="100%"/>
    </div>
    <div class="nav-icon-button-with-text__title">Профиль</div>
  </button>
</ts-dropdown>

<ng-template #dropdownTemplate>

  <div class="profile-dropdown" *ngIf="user$ | async as user">
    <div class="d-flex">
      <div class="d-flex flex-column align-items-center gap-1 mb-2 flex-1 cursor-pointer"
           (click)="openUserProfile()"
           [tsHint]="infoHint"
      >
        <div class="profile-logo d-flex gap-2 mb-2">
          <svg width="12" height="14">
            <use xlink:href="/assets/img/icons/icons.svg#logo-profile"></use>
          </svg>
          <span class="ts-subtitle ts-text-tertiary">ID</span>
        </div>

        <img
          class="avatar"
          [src]="getUserImageSrc(user.image!)"
          alt="avatar"
          height="56"
          width="56"
        />
        <span class="ts-subtitle-strong ts-text-primary">{{ user.firstName + ' ' + user.lastName }}</span>
        <span class="ts-text-secondary ts-text-tertiary">{{ user.phone }}</span>
        <div class="ts-text-info-color">
        <span calss="ts-text-info-color ts-text-primary">
          {{ selectAccount$ | async }}
          <span *ngIf="selectEmployee$ | async as selectEmployee"> - {{ selectEmployee }}</span>
        </span>
        </div>
      </div>

      <div class="d-flex flex-column">
        <button type="button"
                class="ts-btn btn-grey ts-btn-bezeled ts-btn-md ts-btn-icon ts-controls-bar-item ml-3"
                (click)="tsDropdown?.updateOpen(false)"
        >
          <i class="ts-icon ts-icon-actions-Close cursor-pointer ng-star-inserted"></i>
        </button>

        <div class="d-flex flex-1 align-items-center justify-content-center">
          <i class="ts-icon ts-icon-arrow-Expand_right cursor-pointer" (click)="openUserProfile()" [tsHint]="infoHint" ></i>
        </div>
      </div>
    </div>

    <ng-template #infoHint>Перейти в профиль</ng-template>

    <div>
      <div
        class="ts-text-secondary d-flex justify-content-between align-items-center ts-body cursor-pointer"
        [ngClass]="{'mb-2': !isExpanded}"
        (click)="isExpanded = !isExpanded"
      >
        <span class="ts-text-primary">Выбрать рабочее окружение</span>
        <i class="ts-icon cursor-pointer ts-btn-md"
           [ngClass]="{'ts-icon-arrow-Expand_up': isExpanded, 'ts-icon-arrow-Expand_down': !isExpanded}"
        >
        </i>
      </div>
      <ng-container *ngIf="isExpanded">
        <ng-container *ngIf="accounts$ | async as accounts">
          <div *ngFor="let account of accounts" class="mb-2">
            <div class="d-flex gap-2 align-items-center py-2">
              <input
                class="profile-radio cursor-pointer"
                type="radio"
                [name]="account.name"
                [checked]="isAccountSelected(account)"
                (change)="selectAccount(account)"
                id="{{account.id}}">
              <label for="{{account.id}}" class="d-flex flex-column cursor-pointer">
                <span class="ts-text-primary">{{ account.name }}</span>
                <span class="ts-caption-strong ts-text-tertiary">{{ account.position }}</span>
              </label>
            </div>

            <!--      Пока что не реализовано      -->
<!--            <div class="d-flex align-items-center gap-1 ts-body mb-2">-->
<!--              <i class="ts-icon ts-icon-actions-Add ts-text-tertiary text-2lg"></i>-->
<!--              <span>Новое окружение</span>-->
<!--            </div>-->
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div
      *ngIf="currentProductInfo$ | async"
      class="d-flex gap-1 cursor-pointer"
      [routerLink]="subscriptionPageUrl$ | async"
    >
      <div class="profile-block ts-bg-surface-secondary" >
        <span class="ts-text-tertiary ts-body-small">Тариф <span class="text-capitalize">{{ productName$ | async }}</span> </span>
        <span class="ts-subtitle-strong ts-text-primary">{{ tariffName$ | async }}</span>
        <span class="ts-text-success">{{ activeTillDate$ | async }}</span>
      </div>

      <!--      Пока что не реализовано      -->
<!--      <div class="profile-block ts-bg-surface-secondary">-->
<!--        <span class="ts-text-tertiary ts-body-small">Баланс</span>-->
<!--        <span class="ts-subtitle-strong">125,030,299 ₽</span>-->
<!--        <span class="ts-text-success">Пополнить</span>-->
<!--      </div>-->
    </div>

    <div>
      <!--      Пока что не реализовано      -->
<!--      <a-->
<!--        class="profile-menu__item ts-text-primary"-->
<!--        routerLink=""-->
<!--      >-->
<!--        <i class="ts-icon ts-icon-learning-note-2 ts-text-tertiary text-2lg"></i>-->
<!--        <span>Новости</span>-->
<!--      </a>-->

      <a
        class="profile-menu__item ts-text-primary"
        routerLink="/topsellerid/staff/employee"
      >
        <i class="ts-icon ts-icon-users-profile-2user ts-text-tertiary text-2lg"></i>
        <span>Сотрудники и отделы</span>
      </a>

      <a
        class="profile-menu__item ts-text-primary"
        routerLink="/topsellerid/accounts"
      >
        <i class="ts-icon ts-icon-shop-shop ts-text-tertiary text-2lg"></i>
        <span>Аккаунты маркетплесов</span>
      </a>

      <div
        class="profile-menu__item dropdown-item ts-text-primary"
        (click)="toggleTheme()"
        *ngIf="!production"
      >
        <svg width="16" height="16" viewBox="0 0 25 25">
          <use xlink:href="/assets/img/icons/icons.svg#color-mode"></use>
        </svg>
        <span>Смена темы</span>
      </div>
    </div>

    <div>
      <!--      Пока что не реализовано      -->
<!--      <div class="profile-employee">-->
<!--        <img-->
<!--          class="avatar"-->
<!--          [src]="getUserImageSrc(user.image!)"-->
<!--          alt="avatar"-->
<!--          height="24"-->
<!--          width="24"-->
<!--        />-->
<!--        <div class="d-flex flex-column">-->
<!--          <span class="ts-text-primary">Носов Иван</span>-->
<!--          <span class="ts-text-tertiary text-sm">+7 (805) 646-23-20</span>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="profile-employee">-->
<!--        <img-->
<!--          class="avatar"-->
<!--          [src]="getUserImageSrc(user.image!)"-->
<!--          alt="avatar"-->
<!--          height="24"-->
<!--          width="24"-->
<!--        />-->
<!--        <div class="d-flex flex-column">-->
<!--          <span class="ts-text-primary">Хаб_будет, Андрей</span>-->
<!--          <span class="ts-text-tertiary text-sm">longexample@example.com</span>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="profile-employee">-->
<!--        <img-->
<!--          class="avatar"-->
<!--          [src]="getUserImageSrc(user.image!)"-->
<!--          alt="avatar"-->
<!--          height="24"-->
<!--          width="24"-->
<!--        />-->
<!--        <div class="d-flex flex-column">-->
<!--          <span class="ts-text-primary">Пиксель Перфект</span>-->
<!--          <span class="ts-text-tertiary text-sm">@sergei_telegram</span>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="profile-menu__item d-flex align-items-center gap-1 ts-body mb-2">-->
<!--        <i class="ts-icon ts-icon-actions-Add ts-text-tertiary text-2lg"></i>-->
<!--        <span class="ts-text-primary">Добавить аккаунт</span>-->
<!--      </div>-->

      <div class="profile-menu__item d-flex align-items-center gap-1 ts-body" (click)="logout()">
        <i class="ts-icon ts-icon-arrow-logout-2 ts-text-tertiary text-2lg"></i>
        <span class="ts-text-primary">Выйти из аккаунта</span>
      </div>
    </div>
  </div>
</ng-template>
