import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Product} from "../../../data";
import {PriceTypes, ProductPrice} from "../../../data/model/product-prices";
import {CurrencyFormatPipe} from "@topseller/ui/pipes/currency-format";
import { Record } from "@topseller/core";
import { CommonModule } from "@angular/common";

@Component({
  selector: 'ts-product-price-group-cell',
  templateUrl: './product-price-group-cell.component.html',
  styleUrls: ['./product-price-group-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports:[CommonModule],
  standalone: true,
})
export class ProductPriceGroupCellComponent implements OnInit {
  @Input() product!: Product;
  @Input() priceGroupId!: string;
  productPrices?: ProductPrice[];
  pricesToDisplay: Record[] = [];

  constructor(private currencyPipe: CurrencyFormatPipe) {
  }

  ngOnInit(): void {
    this.productPrices = this.product.productPriceGroups?.find(x => x.id === this.priceGroupId)?.productPrices;

    const typesToDisplay = [
      {type: PriceTypes.PRICE_PURCHASE, name: 'Закупочная'},
      {type: PriceTypes.PRICE_SALE, name: 'Продажная'},
      {type: PriceTypes.PRICE_MIN, name: 'Минимальная'}];

    for (const type of typesToDisplay) {
      const price = this.getPrice(type.type);
      this.pricesToDisplay.push({id: type.name, name: price})
    }

  }

  getPrice(priceType: PriceTypes): string {
    const price =this.productPrices?.find(x => x.productPriceType.type === priceType)?.value;
    if (price != null){
      return  this.currencyPipe.transform(price)
    }
    return '-'
  }


}
