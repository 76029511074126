import { Component, Inject, Optional, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Observable, take, tap  } from 'rxjs';

import {
  AccountSelectResponseItems,
  AuthService, AuthTokenResponse,
  CurrentProductInfo, SecurityService,
  selectAccount,
  selectCurrentProductInfo,
  selectEmployee,
  selectUser,
  toggleTheme,
  User,
} from '@topseller/core';
import { DatePipe } from "@angular/common";

@Component({
  selector: 'ts-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  providers: [DatePipe]
})
export class UserInfoComponent {
  public user$: Observable<User> = this.store.select(selectUser);

  public selectedAccount: AccountSelectResponseItems | null = null;

  public selectEmployee$: Observable<string> = this.store.select(selectEmployee).pipe(
    tap((el) => this.selectedAccount = el),
    map(el => el.position)
  );

  public selectAccount$: Observable<User> = this.store.select(selectAccount).pipe(map(el => el.name));

  public isExpanded = false;

  @ViewChild('tsDropdown') tsDropdown?: any;

  currentProductInfo$: Observable<CurrentProductInfo> = this.store.select(selectCurrentProductInfo);
  tariffName$: Observable<string> = this.currentProductInfo$.pipe(
    map(({currentTariffName}) => currentTariffName ?? 'Отсутствует')
  );
  productName$: Observable<string> = this.currentProductInfo$.pipe(
    map(({product}) => product ?? '')
  );
  accounts$: Observable<AccountSelectResponseItems[]> = this.securityService.getUserAccounts();

  isDark = false;
  activeTillDate$: Observable<string> = this.currentProductInfo$.pipe(
    map(({activeTillDate}) => {
      if (!activeTillDate) {
        return 'Бессрочно'
      } else {
        return `До ${this.datePipe.transform(activeTillDate)}`;
      }
    })
  );

  private subscriptionsPagesUrsl: { [key in string]: string } = {
    wbads: '/wb-ads/subscriptions',
    dropship: '/dropship/subscription',
    hub: '/topsellerid/subscription',
    repricer: '/repricer/subscriptions',
  }


  subscriptionPageUrl$: Observable<string> = this.currentProductInfo$.pipe(
    map(({currentProduct}) => this.subscriptionsPagesUrsl[currentProduct!])
  )

  constructor(
    private authService: AuthService,
    private router: Router,
    private store: Store<any>,
    @Optional()
    @Inject('TS_PRODUCTION_MODE')
    public readonly production: boolean,
    private datePipe: DatePipe,
    private securityService: SecurityService
  ) {
  }

  public logout(): void {
    this.authService.logout();
    this.router.navigate(['/login']).finally();
  }

  public openUserProfile(): void {
    if (!this.production) {
      this.router.navigate([`/topsellerid/profile`]);
    }
  }

  public getUserImageSrc(userImage: string): string {
    return userImage ? userImage : 'assets/img/ui/wb-ads/user.svg';
  }

  toggleTheme() {
    this.store.dispatch(toggleTheme())
  }

  public isAccountSelected(account: AccountSelectResponseItems): boolean {
    return this.selectedAccount?.id === account.id;
  }

  public selectAccount(account: AccountSelectResponseItems): void {
    this.selectedAccount = account;

    if(this.selectedAccount === account.id) return;

    const params = { id: account.id  };
    this.authService
      .select(params)
      .pipe(take(1))
      .subscribe({
        next: (response: AuthTokenResponse) => {
          this.authService.setToken(response);
          this.router.navigate(['/']).finally();
        },
        error: (error) => {
          console.log(error);
        },
      });
  }
}
