<div>
  <div [ngClass]="{'d-none':mode==='selected'}">
    <ts-products-catalog (changeView)="viewSelected()"
                         [selectedProducts]="(selectedProducts$|async)!"
                         [tableHeaders]="(displayableTableHeaders$|async)!"
                         (addSelected)="addSelected()"
                         (closeDialog)="close()"/>
  </div>
  <div [ngClass]="{'d-none':mode==='catalog'}">
    <ts-selected-products (changeView)="viewCatalog()"
                          [tableHeaders]="(selectedTableHeaders$|async)!"
                          [selectedProducts]="(selectedProducts$|async)!"
                          (addSelected)="addSelected()"
                          (closeDialog)="close()"/>
  </div>
</div>
