import { Injectable } from '@angular/core';
import { Store } from "@ngrx/store";
import { HubState, selectMetadata } from "../../store";
import { HubEntity } from "../../data";
import { Observable, of, take } from "rxjs";
import { map } from "rxjs/operators";
import { FilterType } from "@topseller/core";
import { Params } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class FiltersService {

  constructor(private store: Store<HubState>) {
  }

  filters: { [key: string]: { [key: string]: string } } = {};

  getDefaultFilter(entityName: HubEntity |string | undefined): Observable<{ [key: string]: string }> {
    if (!entityName) {
      return of({});
    }
    return this.store.select(selectMetadata).pipe(
      take(1),
      map(meta => meta!.filter[entityName]),
      map(filterGroups => {
        const byTargetDictionary: { [key: string]: string } = {};
        for (const filterGroup of filterGroups) {
          if (filterGroup.type === FilterType.DATERANGE && filterGroup.target) {
            filterGroup.filters.forEach(filter => {
              if (filter.defaultValue !== null) {
                let value = filter.defaultValue.data;
                if (filter.type === 'date') {
                  value = this.convertToLocalTime(value);
                }
                byTargetDictionary[`filter[${filterGroup.target}][${filter.target}]`] = value
              }
            })
          } else {
            filterGroup.filters.forEach(filter => {
              if (filter.defaultValue !== null) {
                let value = filter.defaultValue.id;
                if (filter.type === 'date') {
                  value = filter.defaultValue.data;
                  value = this.convertToLocalTime(value);
                }
                byTargetDictionary[`filter[${filter.target}]`] = value;
              }
            });
          }
        }
        return byTargetDictionary;
      }),
    );
  }

  getSavedFilter(entityName: HubEntity | string): { [key: string]: string } | null {
    return this.filters[entityName] || null;
  }

  saveLastAppliedFilter(entityName: HubEntity | string, params: Params): void {
    if (!entityName) {
      return;
    }
    this.filters[entityName] = { ...params };
  }

  /**
   * С сервера приходит значение фильтра по умолчанию для дат в часовом поясе +0
   * а на фронте работаем с локальными датами. нужно сделать перевод.
   * **/
  convertToLocalTime(dateStr: string): string {
    const date = new Date(dateStr);

    // Учитываем смещение времени пользователя
    const timezoneOffset = date.getTimezoneOffset(); // это в минутах
    date.setMinutes(date.getMinutes() + timezoneOffset);

    return date.toISOString();
  }
}

