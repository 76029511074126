import { createFeatureSelector, createSelector } from '@ngrx/store';
import { hubFeatureKey } from '../constants';

import { BaseData, HubEntity } from '../data';

import { HubState } from './metadata.reducer';

export const selectHubFeature = createFeatureSelector<HubState>(hubFeatureKey);

export const selectMetadata = createSelector(
  selectHubFeature,
  (state: HubState) => state.metadata
);

export const selectStatus = (entity: HubEntity|string) =>
  createSelector(selectMetadata, (baseData: BaseData | null) => {
    if (!baseData) {
      return [];
    }
    const { status } = baseData;
    return status[entity];
  });

export const selectFormFields = (entity: HubEntity) =>
  createSelector(selectMetadata, (baseData: BaseData | null) => {
    if (!baseData) {
      return [];
    }
    const { customField } = baseData;
    return customField[entity];
  });

export const selectVat = createSelector(
  selectMetadata,
  (baseData: BaseData | null) => {
    if (!baseData) {
      return [];
    }
    return baseData.vatType;
  }
);

export const selectBarcodeTypes = createSelector(
  selectMetadata,
  (baseData: BaseData | null) => baseData?.barcodeType
);

export const selectDefaultSettingValue = (settingName: string) =>
  createSelector(selectMetadata, (baseData: BaseData | null) => {
    if (!baseData) {
      return null;
    }
    return baseData.defaultValues[settingName];
  });

export const selectDefaultPriceGroup = createSelector(
  selectMetadata,
  (baseData: BaseData | null) => baseData?.defaultValues['productPriceGroup']
);
