<div class="product-sku-cell">
  <div class="caption">Артикул</div>
  <div class="caption-strong text-truncate">
    {{product.sku}}
    <span *ngIf="icon" class="ts-icon caption" [ngClass]="icon"></span>
  </div>
  <div class="caption">Арт. Поставщика</div>
  <ng-container *ngIf="product.supplierSku; else empty">
    <div class="caption-strong text-truncate">
      {{product.supplierSku}}
    <span *ngIf="icon" class="ts-icon caption" [ngClass]="icon"></span>
    </div>
  </ng-container>

  <ng-template #empty> - </ng-template>
</div>
