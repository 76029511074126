import { Inject, Injectable, Optional } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { BASE_PATH, Configuration } from "../";
import { Observable } from "rxjs";
import { Paginated, SubTaskResponse, TsMigration } from "@topseller/core";
import { CustomHttpUrlEncodingCodec } from "../encoder";

@Injectable()
export class MigratorApiService{
  protected basePath = 'http://denis.dev.topseller.ru';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  startTask(masterTaskId:string){
    return this.httpClient.post(
      `${this.basePath}/api/v1/migrator/${masterTaskId}/process`,
      {
        withCredentials: this.configuration.withCredentials,
      }
    );
  }

  transitAllDataIntoHub(accountId: string) {
    return this.httpClient.post(`${this.basePath}/api/v1/migrator/configure/account/${accountId}`,
      {
        withCredentials: this.configuration.withCredentials,
      }
    );
  }

  getMigrationSubTasks(masterTaskId:string):Observable<SubTaskResponse>{
    return this.httpClient.get<SubTaskResponse>(
      `${this.basePath}/api/v1/migrator/${masterTaskId}`,
      {
        withCredentials: this.configuration.withCredentials,
      }
    );
  }

  getMigrations(limit?: number, offset?: number,):Observable<Paginated<TsMigration>>{
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });

    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', <any>offset);
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Paginated<TsMigration>>(
      'get',
      `${this.basePath}/api/v1/migrator`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
      }
    );
  }
}
