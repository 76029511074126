import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginationInstance } from '../base-list';
import { map } from 'rxjs';

@Component({
  selector: 'ts-table-pagination',
  templateUrl: './table-pagination.component.html',
  styleUrls: ['./table-pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TsTablePaginationComponent {
  @Input({ required: true }) public pagination?: PaginationInstance;

  @Output()
  public readonly pageChange = new EventEmitter<number>();

  public limit$ = this.activatedRoute.queryParams.pipe(
    map(({ limit }) => limit || 50)
  );

  constructor(public router: Router, private activatedRoute: ActivatedRoute) {}

  public onCountChange(count: number): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        page: 1,
        limit: count,
      },
      queryParamsHandling: 'merge',
    });
  }
}
