import { User } from "@topseller/core";

export interface ProductPrices {
    isCustom: boolean;
    id?: string;
    name?: string;
    marketplace?: string;
    productPrices?: ProductPrice[];
}

export interface ProductPrice {
    id?: string;
    productPriceType: ProductPriceType;
    value?: number | null;
}

export interface ProductPriceType {
    id?: string;
    name?: string;
    type: PriceTypes;
}

export interface ProductPriceModel {
    id?: string,
    isCustom: boolean,
    modifiedAt?: string,
    modifiedBy?: User,
    name?: string,
    productPriceTypes: PriceTypeModel[]
}

export interface PriceTypeModel {
    id?: string,
    modifiedAt?: string,
    name?: string,
    type: PriceTypes
}

export enum PriceTypes {
    PRICE = 'price',
    PRICE_RRP = 'priceRrp',
    PRICE_SALE = 'priceSale',
    PRICE_MIN = 'priceMin',
    PRICE_PURCHASE = 'pricePurchase',
    CUSTOM = 'custom'
}
