<div class="form-controls-card">
  <div class="grid-container">
    <div class="grid-label">Справочник</div>
    <div class="navigation-toggle">
      <app-toggle-switch [ngModel]="showNav" (changed)="toggleProductGroups()" [hasBackground]="true"
                         label="Навигация" class="d-none d-xl-block"></app-toggle-switch>
    </div>
    <div class="btn-refresh">
      <ts-refresh (click)="refresh()"/>
    </div>
    <div class="btn-close-modal">
      <i class="ts-icon ts-icon-actions-Close text-2lg ts-text-icon-accent-dim-color cursor-pointer"
         (click)="closeDialog.emit()"></i>
    </div>
    <div class="search-input">
      <ts-search class="flex-1" placeholder="Поиск по наименованию, идентификатору"></ts-search>
    </div>
    <div class="toggle-selector">
      <div class="ts-toggle-group">
        <div class="ts-toggle-group__button selected" title="Выбор товаров">
          Выбор товаров
        </div>
        <div class="ts-toggle-group__button" title="Список выбранных"
             (click)="changeView.emit()">
          Список выбранных
        </div>
      </div>
    </div>
    <div class="btn-apply">
              <button
                type="button"
                class="ts-btn primary ts-btn-filled ts-btn-md ts-btn-icon ts-controls-bar-item"
                (click)="apply()"
              >
                <span class="ts-icon ts-icon-actions-Add"></span>
                Добавить позиции ({{selectedProducts.length}})
              </button>
    </div>
  </div>


  <ng-container *ngIf="showNav$ | async">
    <div
      class="page-table-container product-list-page-container container-pagination"
    >
      <div class="nav-tree-container" #lefElement *ngIf="showNav">
        <ng-container *ngIf="navTreeMenu$ | async as menu">
          <app-nav-tree
            [items]="menu"
            [canAddEdit]="false"
            (selectItem)="selectProductGroup($event)"
            [selectedItemId]="(selectedProductGroupId$ | async) || ''"
            navigationRootTitle="Все группы товаров"
          ></app-nav-tree>
        </ng-container>
        <div
          class="grabber"
          tsResize
          [leftResize]="lefElement"
          [rightResize]="rightElement"
          [minWidth]="minNavTreeWidth"
        ></div>
      </div>

      <div
        class="product-list-table"
        [ngClass]="{ 'with-nav': showNav }"
        #rightElement
      >
        <ts-products-catalog-table [tableHeaders]="(displayableTableHeaders$|async)??[]"
                                   [selectedProducts]="selectedProducts"
        ></ts-products-catalog-table>
        <ts-table-pagination
          *ngIf="paginationConfig$ | async as pagination"
          [pagination]="pagination"
        ></ts-table-pagination>
      </div>
    </div>
  </ng-container>


</div>

<!--<ts-form-controls-card label="Справочник">-->
<!--  <div slot="card-actions">-->
<!--    <div class="d-flex gap-2 align-items-center justify-content-between">-->
<!--      <ts-refresh/>-->
<!--      <i class="ts-icon ts-icon-actions-Close text-2lg ts-text-icon-accent-dim-color" (click)="closeDialog.emit()"></i>-->
<!--    </div>-->
<!--    &lt;!&ndash;    <div class="toggle-switch-box ts-controls-bar-item">&ndash;&gt;-->
<!--    &lt;!&ndash;      <app-toggle-switch [ngModel]="showNav" (changed)="toggleProductGroups()"&ndash;&gt;-->
<!--    &lt;!&ndash;                         label="Навигация"></app-toggle-switch>&ndash;&gt;-->
<!--    &lt;!&ndash;    </div>&ndash;&gt;-->
<!--    &lt;!&ndash;    <div class="ts-toggle-group">&ndash;&gt;-->
<!--    &lt;!&ndash;      <div class="ts-toggle-group__button selected" title="Выбор товаров">&ndash;&gt;-->
<!--    &lt;!&ndash;        Выбор товаров&ndash;&gt;-->
<!--    &lt;!&ndash;      </div>&ndash;&gt;-->
<!--    &lt;!&ndash;      <div class="ts-toggle-group__button" title="Список выбранных"&ndash;&gt;-->
<!--    &lt;!&ndash;           (click)="changeView.emit()">&ndash;&gt;-->
<!--    &lt;!&ndash;        Список выбранных&ndash;&gt;-->
<!--    &lt;!&ndash;      </div>&ndash;&gt;-->
<!--    &lt;!&ndash;    </div>&ndash;&gt;-->
<!--    &lt;!&ndash;    <ts-refresh (refresh)="refresh()"/>&ndash;&gt;-->

<!--    &lt;!&ndash;    <i class="ts-icon ts-icon-actions-Close text-2lg ts-text-icon-accent-dim-color" (click)="closeDialog.emit()"></i>&ndash;&gt;-->
<!--  </div>-->


<!--</ts-form-controls-card>-->
