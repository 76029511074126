import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { NavLink } from '@topseller/common';
import { GOODS_ACCOUNTING_VIEW_ROLES, HANDBOOKS_VIEW_ROLES, REPORTS_VIEW_ROLES, ROLES } from "@topseller/core";

@Component({
  selector: 'ts-hub',
  templateUrl: './hub.component.html',
  styleUrls: ['./hub.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class HubComponent {
  public links: NavLink[] = [
    {
      title: 'Главная',
      path: '/hub/dashboard',
      imgPath: '/assets/img/hub/nav-icons/main.svg',
      iconName: 'charts-home-trend-up',
    },
    {
      title: 'Товары',
      path: '/hub/products',
      imgPath: '/assets/img/hub/nav-icons/products.svg',
      iconName: 'goods-box',
      requiredRole: ROLES.ROLE_PRODUCT_VIEW
    },

    {
      title: 'Учёт товара',
      path: '/hub/goods-accounting',
      imgPath: '/assets/img/hub/nav-icons/accounting.svg',
      iconName: 'document-task',
      requiredRole: GOODS_ACCOUNTING_VIEW_ROLES,
      sub: [
        { title: 'Оприходования', path: '/hub/goods-accounting/postings', requiredRole: ROLES.ROLE_POSTING_VIEW },
        { title: 'Списания', path: '/hub/goods-accounting/write-off', requiredRole: ROLES.ROLE_WRITE_OFF_VIEW },
        { title: 'Перемещения', path: '/hub/goods-accounting/movements', requiredRole: ROLES.ROLE_MOVEMENT_VIEW },
        { title: 'Инвентаризация', path: '/hub/goods-accounting/inventory', requiredRole: ROLES.ROLE_INVENTORY_VIEW },
      ],
    },
    {
      title: 'Закупки',
      path: '/hub/supply',
      imgPath: '/assets/img/hub/nav-icons/supply.svg',
      iconName: 'shop-shopping-cart',
      // sub: [
      //   { title: 'Заказы поставщику', path: '/hub/supply/invoice' },
      //   { title: 'Поступления', path: '/hub/supply/receive' },
      //   { title: 'Возвраты', path: '/hub/supply/revert' },
      // ],
    },
    {
      title: 'Продажи',
      path: '/hub/sale',
      imgPath: '/assets/img/hub/nav-icons/sales.svg',
      iconName: 'shop-shop',
      // sub: [
      //   { title: 'Заказы поставщику', path: '/hub/sales/order' },
      //   { title: 'Отгрузки', path: '/hub/sales/recall' },
      //   { title: 'Возвраты', path: '/hub/sales/demand' },
      // ],
    },
    {
      title: 'Деньги',
      path: '/hub/money',
      imgPath: '/assets/img/hub/nav-icons/money.svg',
      iconName: 'money-wallet-2',
      sub: [
        /*
      Временно(!!!) отключено до того как будет готов БЭК
      { title: 'Платежи', path: '/hub/money/payment' },
      */
        /* { title: 'Корректировки', path: '/hub/money/correction' }, */
      ],
    },
    {
      title: 'Отчеты',
      path: '/hub/reports',
      imgPath: '/assets/img/hub/nav-icons/reports.svg',
      iconName: 'document-note',
      requiredRole: REPORTS_VIEW_ROLES,
      sub: [
        { title: 'Прибыльность', path: '/hub/reports/profitability', requiredRole: ROLES.ROLE_REPORT_PROFIT },
        { title: 'Прибыль и убытки', path: '/hub/reports/opiu', requiredRole: ROLES.ROLE_REPORT_LOSS },
        {
          title: 'Остатки',
          path: '/hub/reports/leftovers/product',
          requiredRole: ROLES.ROLE_REPORT_STOCKS,
          aliases: ['/hub/reports/leftovers/warehouse'],
        },
        {
          title: 'Обороты',
          path: '/hub/reports/turnover',
          requiredRole: ROLES.ROLE_REPORT_TURNOVER,
        },
      ],
    },
    {
      title: 'Справочники',
      path: '/hub/handbooks',
      imgPath: '/assets/img/hub/nav-icons/handbooks.svg',
      iconName: 'building-bank',
      requiredRole: HANDBOOKS_VIEW_ROLES,
      sub: [
        { title: 'Организации', path: '/hub/handbooks/company', requiredRole: ROLES.ROLE_ORGANIZATION_VIEW },
        { title: 'Контрагенты', path: '/hub/handbooks/partners', requiredRole: ROLES.ROLE_COUNTERPARTY_VIEW  },
        { title: 'Склады', path: '/hub/handbooks/storage', requiredRole: ROLES.ROLE_WAREHOUSE_VIEW  },
        { title: 'Проекты', path: '/hub/handbooks/projects', requiredRole: ROLES.ROLE_PROJECT_VIEW  },
        { title: 'Статьи расходов и доходов', path: '/hub/handbooks/sources', requiredRole: ROLES.ROLE_SOURCE_VIEW  },
        { title: 'Статьи ДДС', path: '/hub/handbooks/cashflow', requiredRole: ROLES.ROLE_CASHFLOW_SOURCE_VIEW  },
      ],
    },
    {
      title: '',
      path: '/hub/user',
      imgPath: '',
      sub: [
        { title: 'Профиль', path: '/hub/user/profile' },
        { title: 'Подписки', path: '/hub/user/subscriptions' },
      ],
    },
    {
      title: 'Прочее',
      path: '/hub/rest',
      imgPath: 'assets/img/hub/nav-icons/rest-default.svg',
      iconName: 'settings-more',
      sub: [
        { title: 'Файлы', path: '/hub/rest/files' },
        /* Временно(!!!) отключено до того как будет готов БЭК
        // { title: 'Настройки HUB', path: '/hub/rest/settings' },
        { title: 'Истории изменений', path: '/hub/rest/history' },
        */
        { title: 'Корзина', path: '/hub/rest/trash' },
        { title: 'Задачи', path: '/hub/rest/tasks' },
        { title: 'История изменений', path: '/hub/rest/history' },
      ],
    },
  ];
}
