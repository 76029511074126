import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar';

import { FilterDataService, TsCommonModule } from '@topseller/common';
import { ValueUnitPipe } from '@topseller/ui/pipes/value-unit';
import { CurrencyFormatPipe } from '@topseller/ui/pipes/currency-format';
import { LoaderAnimatedModule } from '@topseller/ui/loader-animated';
import {
  DataItemToTableValueMapper,
  TableSettingsService,
  UpdatableTableSettingsService,
  FromStoreTableHeaderService,
} from '@topseller/common/table';
import { APP_NAME } from '@topseller/core';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { StoreModule } from '@ngrx/store';

import { HubCommonModule } from './common/common.module';
import { hubFeatureKey } from './constants';
import { DataModule, EditItemRolesConfig } from './data';
import { CashflowSourceService } from './data/api/cashflow-source.service';
import { HubRoutingModule } from './hub-routing.module';
import { HubComponent } from './hub.component';
import { CatalogsDataService } from './common/services/catalogs-data.service';
import { metadataReducer } from './store/metadata.reducer';
import { ListItemsDeleteService } from "@topseller/common/base-list";
import { HubFilterDataService } from "./common/services";
import { EDIT_ITEM_ROLES_CONFIG } from "./common";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  declarations: [HubComponent],
  imports: [
    CommonModule,
    DataModule,
    HubCommonModule,
    HubRoutingModule,
    TsCommonModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    StoreModule.forFeature(hubFeatureKey, metadataReducer),
    LoaderAnimatedModule,
  ],
  exports: [],
  providers: [
    CatalogsDataService,
    CashflowSourceService,
    ListItemsDeleteService,
    {
      provide: MatDialogRef,
      useValue: {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        close: () => {},
      },
    },
    {
      provide: APP_NAME,
      useValue: 'hub',
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    ValueUnitPipe,
    CurrencyFormatPipe,
    DataItemToTableValueMapper,
    {
      provide: TableSettingsService,
      useClass: UpdatableTableSettingsService,
    },
    FromStoreTableHeaderService,
    {
      provide:FilterDataService,
      useClass: HubFilterDataService
    },
    {
      provide: EDIT_ITEM_ROLES_CONFIG,
      useValue: EditItemRolesConfig,
    }
  ],
})
export class HubModule {}
