import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'ts-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class TsSearchComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  @Input() placeholder = 'Я ищу...';

  @Input() searchText: boolean = false;

  public search: FormControl<string>;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private route: ActivatedRoute) {
    this.search = new FormControl('') as FormControl<string>;
    route.queryParams.subscribe(params => this.search.patchValue(params['search'] || ''));
  }

  ngOnInit(): void {
    this.search.valueChanges
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe((search) => this.onSearchChange(search || ''));
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private onSearchChange(search: string = ''): void {
    const queryParams = {
      page: 1,
      ...(this.searchText ? {'filter[searchText]': search} : {search}),
    };

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
    });
  }
}
