export const ROLES = {
  ROLE_OWNER: 'ROLE_OWNER',
  ROLE_COMMON_SETTINGS_VIEW: 'ROLE_COMMON_SETTINGS_VIEW',
  ROLE_COMMON_SETTINGS: 'ROLE_COMMON_SETTINGS',
  ROLE_DIRECTORY_VIEW: "ROLE_DIRECTORY_VIEW",
  ROLE_DIRECTORY_ADMIN: "ROLE_DIRECTORY_ADMIN",
  ROLE_PRODUCT_VIEW: "ROLE_PRODUCT_VIEW",
  ROLE_PRODUCT_EDIT: "ROLE_PRODUCT_EDIT",
  ROLE_PRODUCT_DELETE: "ROLE_PRODUCT_DELETE",
  ROLE_PRODUCT_ADMIN: "ROLE_PRODUCT_ADMIN",
  ROLE_ORGANIZATION_VIEW: "ROLE_ORGANIZATION_VIEW",
  ROLE_ORGANIZATION_EDIT: "ROLE_ORGANIZATION_EDIT",
  ROLE_ORGANIZATION_DELETE: "ROLE_ORGANIZATION_DELETE",
  ROLE_ORGANIZATION_ADMIN: "ROLE_ORGANIZATION_ADMIN",
  ROLE_COUNTERPARTY_VIEW: "ROLE_COUNTERPARTY_VIEW",
  ROLE_COUNTERPARTY_EDIT: "ROLE_COUNTERPARTY_EDIT",
  ROLE_COUNTERPARTY_DELETE: "ROLE_COUNTERPARTY_DELETE",
  ROLE_COUNTERPARTY_ADMIN: "ROLE_COUNTERPARTY_ADMIN",
  ROLE_WAREHOUSE_VIEW: "ROLE_WAREHOUSE_VIEW",
  ROLE_WAREHOUSE_EDIT: "ROLE_WAREHOUSE_EDIT",
  ROLE_WAREHOUSE_DELETE: "ROLE_WAREHOUSE_DELETE",
  ROLE_WAREHOUSE_ADMIN: "ROLE_WAREHOUSE_ADMIN",
  ROLE_PROJECT_VIEW: "ROLE_PROJECT_VIEW",
  ROLE_PROJECT_EDIT: "ROLE_PROJECT_EDIT",
  ROLE_PROJECT_DELETE: "ROLE_PROJECT_DELETE",
  ROLE_PROJECT_ADMIN: "ROLE_PROJECT_ADMIN",
  ROLE_SOURCE_VIEW: "ROLE_SOURCE_VIEW",
  ROLE_SOURCE_EDIT: "ROLE_SOURCE_EDIT",
  ROLE_SOURCE_DELETE: "ROLE_SOURCE_DELETE",
  ROLE_SOURCE_ADMIN: "ROLE_SOURCE_ADMIN",
  ROLE_CASHFLOW_SOURCE_VIEW: "ROLE_CASHFLOW_SOURCE_VIEW",
  ROLE_CASHFLOW_SOURCE_EDIT: "ROLE_CASHFLOW_SOURCE_EDIT",
  ROLE_CASHFLOW_SOURCE_DELETE: "ROLE_CASHFLOW_SOURCE_DELETE",
  ROLE_CASHFLOW_SOURCE_ADMIN: "ROLE_CASHFLOW_SOURCE_ADMIN",
  ROLE_EXPENSE_VIEW: "ROLE_EXPENSE_VIEW",
  ROLE_EXPENSE_EDIT: "ROLE_EXPENSE_EDIT",
  ROLE_EXPENSE_DELETE: "ROLE_EXPENSE_DELETE",
  ROLE_EXPENSE_ADMIN: "ROLE_EXPENSE_ADMIN",
  ROLE_DEPARTMENT_VIEW: "ROLE_DEPARTMENT_VIEW",
  ROLE_DEPARTMENT_EDIT: "ROLE_DEPARTMENT_EDIT",
  ROLE_DEPARTMENT_DELETE: "ROLE_DEPARTMENT_DELETE",
  ROLE_DEPARTMENT_ADMIN: "ROLE_DEPARTMENT_ADMIN",
  ROLE_EMPLOYEE_VIEW: "ROLE_EMPLOYEE_VIEW",
  ROLE_EMPLOYEE_EDIT: "ROLE_EMPLOYEE_EDIT",
  ROLE_EMPLOYEE_DELETE: "ROLE_EMPLOYEE_DELETE",
  ROLE_EMPLOYEE_ADMIN: "ROLE_EMPLOYEE_ADMIN",
  ROLE_CUSTOM_FIELD_VIEW: "ROLE_CUSTOM_FIELD_VIEW",
  ROLE_CUSTOM_FIELD_EDIT: "ROLE_CUSTOM_FIELD_EDIT",
  ROLE_CUSTOM_FIELD_DELETE: "ROLE_CUSTOM_FIELD_DELETE",
  ROLE_CUSTOM_FIELD_ADMIN: "ROLE_CUSTOM_FIELD_ADMIN",
  ROLE_MARKETPLACE_VIEW: "ROLE_MARKETPLACE_VIEW",
  ROLE_MARKETPLACE_ADMIN: "ROLE_MARKETPLACE_ADMIN",
  ROLE_MARKETPLACE_ACCOUNT_VIEW: "ROLE_MARKETPLACE_ACCOUNT_VIEW",
  ROLE_MARKETPLACE_ACCOUNT_EDIT: "ROLE_MARKETPLACE_ACCOUNT_EDIT",
  ROLE_MARKETPLACE_ACCOUNT_DELETE: "ROLE_MARKETPLACE_ACCOUNT_DELETE",
  ROLE_MARKETPLACE_ACCOUNT_ADMIN: "ROLE_MARKETPLACE_ACCOUNT_ADMIN",
  ROLE_BRAND_VIEW: "ROLE_BRAND_VIEW",
  ROLE_BRAND_EDIT: "ROLE_BRAND_EDIT",
  ROLE_BRAND_ADMIN: "ROLE_BRAND_ADMIN",
  ROLE_STATUS_VIEW: "ROLE_STATUS_VIEW",
  ROLE_STATUS_EDIT: "ROLE_STATUS_EDIT",
  ROLE_STATUS_DELETE: "ROLE_STATUS_DELETE",
  ROLE_STATUS_ADMIN: "ROLE_STATUS_ADMIN",
  ROLE_PRODUCT_PRICE_VIEW: "ROLE_PRODUCT_PRICE_VIEW",
  ROLE_PRODUCT_PRICE_EDIT: "ROLE_PRODUCT_PRICE_EDIT",
  ROLE_PRODUCT_PRICE_DELETE: "ROLE_PRODUCT_PRICE_DELETE",
  ROLE_PRODUCT_PRICE_ADMIN: "ROLE_PRODUCT_PRICE_ADMIN",
  ROLE_COMMENTARY_VIEW: "ROLE_COMMENTARY_VIEW",
  ROLE_COMMENTARY_EDIT: "ROLE_COMMENTARY_EDIT",
  ROLE_COMMENTARY_DELETE: "ROLE_COMMENTARY_DELETE",
  ROLE_COMMENTARY_ADMIN: "ROLE_COMMENTARY_ADMIN",
  ROLE_POSTING_VIEW: "ROLE_POSTING_VIEW",
  ROLE_POSTING_EDIT: "ROLE_POSTING_EDIT",
  ROLE_POSTING_DELETE: "ROLE_POSTING_DELETE",
  ROLE_POSTING_ADMIN: "ROLE_POSTING_ADMIN",
  ROLE_WRITE_OFF_VIEW: "ROLE_WRITE_OFF_VIEW",
  ROLE_WRITE_OFF_EDIT: "ROLE_WRITE_OFF_EDIT",
  ROLE_WRITE_OFF_DELETE: "ROLE_WRITE_OFF_DELETE",
  ROLE_WRITE_OFF_ADMIN: "ROLE_WRITE_OFF_ADMIN",
  ROLE_MOVEMENT_VIEW: "ROLE_MOVEMENT_VIEW",
  ROLE_MOVEMENT_EDIT: "ROLE_MOVEMENT_EDIT",
  ROLE_MOVEMENT_DELETE: "ROLE_MOVEMENT_DELETE",
  ROLE_MOVEMENT_ADMIN: "ROLE_MOVEMENT_ADMIN",
  ROLE_INVENTORY_VIEW: "ROLE_INVENTORY_VIEW",
  ROLE_INVENTORY_EDIT: "ROLE_INVENTORY_EDIT",
  ROLE_INVENTORY_DELETE: "ROLE_INVENTORY_DELETE",
  ROLE_INVENTORY_ADMIN: "ROLE_INVENTORY_ADMIN",
  ROLE_PURCHASE_VIEW: "ROLE_PURCHASE_VIEW",
  ROLE_PURCHASE_EDIT: "ROLE_PURCHASE_EDIT",
  ROLE_PURCHASE_DELETE: "ROLE_PURCHASE_DELETE",
  ROLE_PURCHASE_ADMIN: "ROLE_PURCHASE_ADMIN",
  ROLE_RECEIVE_VIEW: "ROLE_RECEIVE_VIEW",
  ROLE_RECEIVE_EDIT: "ROLE_RECEIVE_EDIT",
  ROLE_RECEIVE_DELETE: "ROLE_RECEIVE_DELETE",
  ROLE_RECEIVE_ADMIN: "ROLE_RECEIVE_ADMIN",
  ROLE_REVERT_VIEW: "ROLE_REVERT_VIEW",
  ROLE_REVERT_EDIT: "ROLE_REVERT_EDIT",
  ROLE_REVERT_DELETE: "ROLE_REVERT_DELETE",
  ROLE_REVERT_ADMIN: "ROLE_REVERT_ADMIN",
  ROLE_ORDER_VIEW: "ROLE_ORDER_VIEW",
  ROLE_ORDER_EDIT: "ROLE_ORDER_EDIT",
  ROLE_ORDER_DELETE: "ROLE_ORDER_DELETE",
  ROLE_ORDER_ADMIN: "ROLE_ORDER_ADMIN",
  ROLE_DEMAND_VIEW: "ROLE_DEMAND_VIEW",
  ROLE_DEMAND_EDIT: "ROLE_DEMAND_EDIT",
  ROLE_DEMAND_DELETE: "ROLE_DEMAND_DELETE",
  ROLE_DEMAND_ADMIN: "ROLE_DEMAND_ADMIN",
  ROLE_RECALL_VIEW: "ROLE_RECALL_VIEW",
  ROLE_RECALL_EDIT: "ROLE_RECALL_EDIT",
  ROLE_RECALL_DELETE: "ROLE_RECALL_DELETE",
  ROLE_RECALL_ADMIN: "ROLE_RECALL_ADMIN",
  ROLE_PAYMENT_VIEW: "ROLE_PAYMENT_VIEW",
  ROLE_PAYMENT_EDIT: "ROLE_PAYMENT_EDIT",
  ROLE_PAYMENT_DELETE: "ROLE_PAYMENT_DELETE",
  ROLE_PAYMENT_ADMIN: "ROLE_PAYMENT_ADMIN",
  ROLE_FILE_VIEW: "ROLE_FILE_VIEW",
  ROLE_FILE_EDIT: "ROLE_FILE_EDIT",
  ROLE_FILE_DELETE: "ROLE_FILE_DELETE",
  ROLE_FILE_ADMIN: "ROLE_FILE_ADMIN",
  ROLE_AUDIT_VIEW: "ROLE_AUDIT_VIEW",
  ROLE_AUDIT_ADMIN: "ROLE_AUDIT_ADMIN",
  ROLE_WEBHOOK_VIEW: "ROLE_WEBHOOK_VIEW",
  ROLE_WEBHOOK_EDIT: "ROLE_WEBHOOK_EDIT",
  ROLE_WEBHOOK_DELETE: "ROLE_WEBHOOK_DELETE",
  ROLE_WEBHOOK_ADMIN: "ROLE_WEBHOOK_ADMIN",
  ROLE_ACCOUNT_APPLICATION_VIEW: "ROLE_ACCOUNT_APPLICATION_VIEW",
  ROLE_ACCOUNT_APPLICATION_ADMIN: "ROLE_ACCOUNT_APPLICATION_ADMIN",
  ROLE_DASHBOARD: "ROLE_DASHBOARD",
  ROLE_REPORT_STOCKS: "ROLE_REPORT_STOCKS",
  ROLE_REPORT_TURNOVER: "ROLE_REPORT_TURNOVER",
  ROLE_REPORT_PROFIT: "ROLE_REPORT_PROFIT",
  ROLE_REPORT_LOSS: "ROLE_REPORT_LOSS",
};


export const HANDBOOKS_VIEW_ROLES = [
  ROLES.ROLE_ORGANIZATION_VIEW,
  ROLES.ROLE_WAREHOUSE_VIEW,
  ROLES.ROLE_COUNTERPARTY_VIEW,
  ROLES.ROLE_PROJECT_VIEW,
  ROLES.ROLE_CASHFLOW_SOURCE_VIEW,
  ROLES.ROLE_SOURCE_VIEW
]

export const REPORTS_VIEW_ROLES =[
  ROLES.ROLE_REPORT_LOSS,
  ROLES.ROLE_REPORT_PROFIT,
  ROLES.ROLE_REPORT_STOCKS,
  ROLES.ROLE_REPORT_TURNOVER
]

export const STAFF_VIEW_ROLES = [
  ROLES.ROLE_DEPARTMENT_VIEW,
  ROLES.ROLE_EMPLOYEE_VIEW
]

export const COMMENTS_FILES_VIEW_ROLES = [
  ROLES.ROLE_COMMENTARY_VIEW,
  ROLES.ROLE_FILE_VIEW,
]

export const GOODS_ACCOUNTING_VIEW_ROLES = [
  ROLES.ROLE_INVENTORY_VIEW,
  ROLES.ROLE_POSTING_VIEW,
  ROLES.ROLE_MOVEMENT_EDIT,
  ROLES.ROLE_WRITE_OFF_VIEW
]
