import { Directive, Inject, OnInit, Optional } from "@angular/core";
import { ActionItem } from "@topseller/ui";
import { BatchActionsService } from "../services";
import { BaseTableWithFilterComponent } from "./base-table-with-filter-component.directive";
import { TsDataEntity } from "@topseller/core";
import { LIST_CONTROLS_CONFIG } from "../tokens";
import { ListControlsConfig } from "../../data";

@Directive()
export class BaseDocumentBatchActionsComponent<T extends TsDataEntity> implements OnInit{
  canReserve = false;

  batchActions: ActionItem[] = [{
    action: () => this.setIsCommitted(true),
    title: 'Провести',
    requiredRole: this.config?.editItemRole
  },
    {
      action: () => this.setIsCommitted(false),
      title: 'Снять проведение',
      requiredRole: this.config?.editItemRole
    }
  ]

  constructor(private batchActionsService: BatchActionsService,
              public host: BaseTableWithFilterComponent<T>,
              @Optional() @Inject(LIST_CONTROLS_CONFIG) protected config?: ListControlsConfig) {

  }

  ngOnInit() {
    if (this.canReserve) {
      this.batchActions.unshift({
        action: () => this.setIsReserve(true),
        title: 'Резервировать',
        requiredRole: this.config?.editItemRole
      });
      this.batchActions.unshift({
        action: () => this.setIsReserve(false),
        title: 'Снять резерв',
        requiredRole: this.config?.editItemRole
      });
    }
  }

  private setIsCommitted(isCommitted: boolean) {
    this.host.setLoading();
    this.batchActionsService.performAction(this.host.selectedColumnsId$.getValue(), {isCommitted},
      {
        successMessage: isCommitted ? 'Документы проведены' : 'Проведение снято',
        postback: () => this.host.refresh(true)

      })
  }

  private setIsReserve(isReserve: boolean) {
    this.host.setLoading();
    this.batchActionsService.performAction(this.host.selectedColumnsId$.getValue(), {isReserve},
      {
        successMessage: isReserve ? 'Документы зарезервированы' : 'Документы сняты с резерва',
        postback: () => this.host.refresh(true)

      })
  }
}
