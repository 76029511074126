import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  Observable,
  shareReplay,
  Subject,
  switchMap,
  takeUntil,
  tap,
  EMPTY,
  catchError,
  of,
} from 'rxjs';

import { Directive, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import {
  ListOptions,
  ListQueryFn,
  ListQueryParams,
  MappingFn,
  PaginationInstance,
  toFilterString,
} from './types';

import { Paginated, TsDataEntity } from '@topseller/core';
import { ActionItem } from '@topseller/ui';

@Directive()
export class BaseListComponent<ItemType extends TsDataEntity<any>>
  implements OnInit, OnDestroy
{
  public items$?: Observable<ItemType[]>;
  public items: ItemType[] = [];
  public currentFilter$!: Observable<ListQueryParams>;
  public totalItemsCount$: Observable<number> = of(0);
  public totalCount = 0;
  itemsIds$: Observable<string[]> = of([]);
  itemsIds: string[] = [];
  tableIdentifier?: string;
  public batchDeleteActionItem: ActionItem = {
    title: `Удалить (0)`,
    action: () => this.batchDelete(),
    titleFn: () => this.deletionString,
  };

  get deletionString(): string {
    return `Удалить (${this.selectedColumnsId$.getValue().length})`;
  }

  public isDisabled(item: ItemType) {
    return false;
  }

  public batchDelete(): void {
    return;
  }

  public isDisabledBtnAction = true;

  public paginationConfig$?: Observable<PaginationInstance>;

  private defaults: ListOptions = { limit: 50 };
  protected destroy$: Subject<void> = new Subject<void>();

  public isLoading$ = new BehaviorSubject<boolean>(true);
  protected refresh$ = new BehaviorSubject<boolean>(true);

  private mappingFn?: MappingFn<ItemType>;

  public allSelected$ = new BehaviorSubject<boolean>(false);
  public selectedColumnsId$ = new BehaviorSubject<string[]>([]);
  public indeterminate$: Observable<boolean> = combineLatest([
    this.allSelected$,
    this.selectedColumnsId$,
  ]).pipe(map(([allSelected, ids]) => !allSelected && !!ids.length));
  public readonly routerState: Record<string, unknown>;

  public selectedItemsCount$: Observable<number> = this.selectedColumnsId$.pipe(
    map((selected) => {
      selected.length !== 0
        ? (this.isDisabledBtnAction = false)
        : (this.isDisabledBtnAction = true);

      return selected.length;
    })
  );

  /**
   * извлекаем из адресной строки всё что связано с фильтром и преобразуем в объект.
   * */
  extractFilter(params: Params): Record<string, any> {
    const filter: Record<string, any> = {};

    for (const key of Object.keys(params)) {
      if (key.startsWith('filter[')) {
        const matches = key.match(/filter\[([^\]]+)\](?:\[([^\]]+)\])?/);
        if (matches) {
          if (matches[2]) {
            if (!filter[matches[1]]) {
              filter[matches[1]] = {};
            }
            filter[matches[1]][matches[2]] = params[key];
          } else {
            filter[matches[1]] = params[key];
          }
        }
      }
    }
    return filter;
  }
  private listQueryFn: ListQueryFn<ItemType> = () => EMPTY;

  constructor(protected route: ActivatedRoute) {
    const router = inject(Router, { optional: true });
    this.routerState = router?.getCurrentNavigation()?.extras.state || {};
  }

  public setQueryFn(
    listQueryFn: ListQueryFn<ItemType>,
    mappingFn?: MappingFn<ItemType>
  ): void {
    this.listQueryFn = listQueryFn;
    this.mappingFn = mappingFn
      ? mappingFn
      : ({ items, pagination: { total = 0 } }: Paginated<ItemType>) => ({
          items,
          total,
        });
  }

  public ngOnInit(): void {
    if (!this.listQueryFn) {
      throw new Error(
        `Не найден метод listQueryFn. Необходимо вызвать super.setQueryFn() в конструкторе.`
      );
    }
    this.initializeCurrentFilter();
    const fetchPage = ({
      search,
      limit = this.defaults.limit,
      page: currentPage,
      filter,
      filterString,
      sort,
      sortName,
      sortDir,
    }: ListQueryParams) => {
      const offset = currentPage * limit;
      this.resetAllSelectedCheckboxes();
      return this.listQueryFn({
        offset,
        limit,
        search,
        filter,
        filterString,
        sort,
        sortName,
        sortDir,
      });
    };

    const result$: Observable<Paginated<ItemType>> = combineLatest([
      this.currentFilter$.pipe(distinctUntilChanged(this.filtersAreEqual)), //выполняем запрос только если фильтр изменился
      this.refresh$,
    ]).pipe(
      tap(([_, refreshValue]) => {
        this.isLoading$.next(refreshValue);
      }),
      map(([fetchPage]) => fetchPage),
      switchMap(fetchPage),
      catchError((error) => {
        const message =
          error?.error?.length && error.errors[0].message
            ? error.errors[0].message
            : error?.message || 'Что-то пошло не так';
        this.isLoading$.next(false);
        throw new Error(message);
      }),
      tap(() => this.isLoading$.next(false)),
      shareReplay(1),
      takeUntil(this.destroy$)
    );

    this.items$ = result$.pipe(
      map((data: Paginated<ItemType>) =>
        this.mappingFn ? this.mappingFn(data).items : []
      ),
      tap((data: ItemType[]) => (this.items = data)),
      tap((data) => (this.itemsIds = data.map((x) => x.id)))
    );

    this.itemsIds$ = this.items$?.pipe(
      map((items) => items.map(({ id }) => id)),
      tap((d) => (this.itemsIds = d))
    );

    this.totalItemsCount$ = result$.pipe(
      tap((data) => (this.totalCount = data.items.length)),
      map((data: Paginated<ItemType>) =>
        this.mappingFn ? this.mappingFn(data).total : 0
      )
    );

    this.paginationConfig$ = combineLatest({
      totalItems: this.totalItemsCount$,
      filters: this.currentFilter$,
    }).pipe(
      map(({ filters, totalItems }) => {
        const { page, limit } = filters;
        const itemsPerPage = limit || this.defaults.limit;

        return {
          itemsPerPage,
          currentPage:
            page < 0 || page * itemsPerPage + 1 > totalItems ? 0 : page,
          totalItems,
        };
      })
    );

    this.selectedItemsCount$
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (count) => (this.batchDeleteActionItem.title = `Удалить (${count})`)
      );
  }
  private filtersAreEqual(
    arrA: ListQueryParams,
    arrB: ListQueryParams
  ): boolean {
    const paramsA = arrA;
    const paramsB = arrB;

    function equal<T>(a: T, b: T, keys: (keyof T)[]): boolean {
      return !keys.some((key: keyof T) => a[key] !== b[key]);
    }

    return equal(paramsA, paramsB, [
      'page',
      'limit',
      'sort',
      'search',
      'filterString',
      'sortName',
      'sortDir',
    ]);
  }
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete;
  }

  /**
   * обновить данные
   * withLoader - показывать при этом лоадер или нет.
   * */
  public refresh(withLoader = true): void {
    this.refresh$.next(withLoader);
    this.selectedColumnsId$.next([]);
  }

  public setLoading() {
    this.isLoading$.next(true);
  }

  public selectAllColumns(
    selected: boolean,
    itemIds: (string | undefined)[]
  ): void {
    const allSelected = this.allSelected$.getValue();
    this.allSelected$.next(!allSelected);

    if (allSelected) {
      this.selectedColumnsId$.next([]);
    } else {
      this.selectedColumnsId$.next(itemIds as string[]);
    }
  }

  public toggleSelectAllRows() {
    const allSelected = this.allSelected$.getValue();
    this.allSelected$.next(!allSelected);

    if (allSelected) {
      this.selectedColumnsId$.next([]);
    } else {
      this.selectedColumnsId$.next(this.itemsIds);
    }
  }

  public isColumnSelected(id: string): Observable<boolean> {
    return this.selectedColumnsId$.pipe(
      map((selectedIds) => selectedIds.some((selectedId) => selectedId === id))
    );
  }

  public selectColumn(selected: boolean, id: string) {
    const selectedIds = this.selectedColumnsId$.getValue();
    const selectedList = selectedIds.includes(id)
      ? selectedIds.filter((selectedId) => selectedId !== id)
      : [...selectedIds, id];

    this.selectedColumnsId$.next(selectedList);
    this.allSelected$.next(this.totalCount === selectedList.length);
  }
  public toggleRowSelection(id: string) {
    const selectedIds = this.selectedColumnsId$.getValue();
    const selectedList = selectedIds.includes(id)
      ? selectedIds.filter((selectedId) => selectedId !== id)
      : [...selectedIds, id];

    this.selectedColumnsId$.next(selectedList);
    this.allSelected$.next(this.totalCount === selectedList.length);
  }

  protected initializeCurrentFilter(): void {
    this.currentFilter$ = this.route.queryParams.pipe(
      map((params: Params) => {
        const filter = this.extractFilter(params);
        const filterString = toFilterString(filter);
        const { page, search = '', sortName, sortDir, limit } = params;

        return {
          page: page ? page - 1 : 0,
          search,
          limit,
          filter,
          filterString,
          sortName,
          sortDir,
        };
      }),
      distinctUntilChanged()
    );
  }

  public resetAllSelectedCheckboxes() {
    this.allSelected$.next(false);
    this.selectedColumnsId$.next([]);
  }
}
