import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from "@topseller/core";
import { Observable, of } from "rxjs";
import { TsHintModule } from '../hint';

@Component({
  selector: 'ts-role-access-wrapper',
  standalone: true,
  imports: [CommonModule, TsHintModule],
  templateUrl: './role-access-wrapper.component.html',
  styleUrls: ['./role-access-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoleAccessWrapperComponent implements OnInit{
  @Input() requiredRole: string | string[] | undefined | null;

  hasRole$:Observable<boolean> = of(false);

  constructor(private authService:AuthService) {

  }

  ngOnInit(): void {
    this.hasRole$ = this.authService.employeeHasRole(this.requiredRole);
  }
}
