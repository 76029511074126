import {Injectable} from '@angular/core';
import {
  CHAR_EM_DASH,
  CHAR_EN_DASH,
  CHAR_FIGURE_DASH,
  CHAR_HORIZONTAL_BAR,
  CHAR_HYPHEN,
  CHAR_HYPHEN_MINUS,
  CHAR_MINUS,
  CHAR_NO_BREAK_SPACE,
  CHAR_SMALL_HYPHEN_MINUS,
} from '@topseller/cdk/keyboard';

import {Selection} from './helpers/selection.helper';
import {CursorMap, CursorHelper} from './helpers/cursor.helper';
import {NumberFormatOptions} from './input-number.types';

export const DEFAULT_NUMBER_FORMAT: Required<NumberFormatOptions> = {
  precision: 2, //Infinity,
  decimalSeparator: ',',
  unsigned: true,
  thousandSeparator: CHAR_NO_BREAK_SPACE,
  hideTrailingZeros: false,
  formatMultiplier: 1,
};

export const isNullable = (value: unknown): value is null | undefined => {
  return value === null || value === undefined;
};

export interface FormattingInfo {
  raw: string;
  formatted: string;
  cursorMap: CursorMap;
}

const MAX_SAFE_DIGITS = 15;

@Injectable()
export class InputNumberService {
  private options = DEFAULT_NUMBER_FORMAT;

  public static unformatString(value: string): string {
    return value
      .replace(/\s/g, '')
      .replace(',', '.')
      .replace(
        new RegExp(
          `/[${CHAR_MINUS}${CHAR_HYPHEN}${CHAR_SMALL_HYPHEN_MINUS}${CHAR_HYPHEN_MINUS}${CHAR_FIGURE_DASH}${CHAR_EN_DASH}${CHAR_EM_DASH}${CHAR_HORIZONTAL_BAR}]/g`
        ),
        '-'
      );
  }

  public get isNegativeAllowed(): boolean {
    return !this.options.unsigned;
  }

  public initConfig(options: NumberFormatOptions): void {
    this.options = { ...this.options, ...options };
  }

  public getSeparatorPosition(value: string): number {
    return value.indexOf(this.options.decimalSeparator);
  }

  public format(
    value: number | null,
    supressTrailingZero: boolean = true
  ): string {
    if (isNullable(value)) {
      return '';
    }

    const displayValue = value / this.options.formatMultiplier;
    return this.formatString(displayValue.toString(), supressTrailingZero);
  }

  public parse(value: string): number | null {
    const cleaned = this.unformatString(value);
    if (!cleaned) {
      return null;
    }

    const destructed = this.destructString(cleaned);
    if (!destructed) {
      return null;
    }

    const valueToParse =
      destructed.sign +
      (destructed.integer || '0') +
      (destructed.delimiter || '.') +
      (destructed.fraction || '0');

    const result =
      parseFloat(valueToParse) *
      this.options.formatMultiplier;

    return +result.toFixed(0);
  }

  public safeInsert(value: string, start: number, end: number, input: string) {
    if (input && start === 0 && end === value.length) {
      const extracted = this.extractValid(input);
      if (!extracted) {
        return null;
      }

      return this.insert(value, start, end, extracted);
    }

    const prefix = value.substring(0, start);
    const suffix = value.substring(end);
    const combined = prefix + input + suffix;

    if (
      !combined ||
      combined.match(new RegExp(`^${this.options.decimalSeparator}0*$`))
    ) {
      return { value: '', position: 0 };
    }
    if (this.isValidString(combined)) {
      return this.insert(value, start, end, input);
    }
    return null;
  }

  public insert(value: string, start: number, end: number, input: string) {
    const prefix = value.substring(0, start);
    const suffix = value.substring(end);

    const { cursorMap: rawCursorMap }: FormattingInfo = this.getInfo(value);
    const rawPosition: number = CursorHelper.toRawPosition(rawCursorMap, start);
    const { cursorMap, formatted }: FormattingInfo = this.getInfo(
      prefix + input + suffix
    );
    const formattedPosition = CursorHelper.toFormattedPosition(
      cursorMap,
      rawPosition + input.length
    );

    return { value: formatted, position: formattedPosition };
  }

  public moveCursor(value: string, selection: Selection, step: number): number {
    if (selection.start === selection.end) {
      return CursorHelper.calculatePosition(
        this.getInfo(value).cursorMap,
        selection.start,
        step
      );
    } else if (step < 0) {
      return selection.start;
    }

    return selection.end;
  }

  private getInfo(value: string): FormattingInfo {
    const raw = this.unformatString(value);
    const formatted = this.formatString(value, true);
    const cursorMap = this.getCursorMap(formatted);

    return { raw, formatted, cursorMap };
  }

  private unformatString(value: string): string {
    return value
      .replace(/\s/g, '')
      .replace(',', '.')
      .replace(
        new RegExp(
          `/[${CHAR_MINUS}${CHAR_HYPHEN}${CHAR_SMALL_HYPHEN_MINUS}${CHAR_HYPHEN_MINUS}${CHAR_FIGURE_DASH}${CHAR_EN_DASH}${CHAR_EM_DASH}${CHAR_HORIZONTAL_BAR}]/g`
        ),
        '-'
      );
  }

  /**
   * Функция форматирует число в строку. Целую часть разбивает на группы по 3 символа
   * используя разделитель указанный в настройках. добавляет необходимое количество знаков после запятой
   */
  private formatString(value: string, supressTrailingZeros: boolean): string {
    const unformattedValue = this.unformatString(value);
    const {
      sign = '',
      integer: rawInteger = '',
      delimiter = '',
      fraction = '',
    } = this.destructString(unformattedValue) || {};
    const integer = rawInteger ? parseInt(rawInteger, 10).toString() : '0';
    const {
      precision: defaultDecimalLimit,
      hideTrailingZeros,
      thousandSeparator,
    } = this.options;

    const decimalLimit = defaultDecimalLimit || fraction.length;

    if (hideTrailingZeros) {
      // const trailingLimit = fraction.replace(/0+$/, '').length;
      // decimalLimit =
      //   fraction.length > decimalLimit ? decimalLimit : trailingLimit;
    }

    const parts = [];

    const blockSize = 3;
    const start = ((integer.length - 1) % blockSize) - blockSize + 1;
    for (let i = start; i < integer.length; i += blockSize) {
      parts.push(integer.substring(Math.max(i, 0), i + blockSize));
    }

    let result = parts.join(thousandSeparator);

    if (delimiter || (decimalLimit && !supressTrailingZeros)) {
      result += ',';
      //.replace(/0+$/, '');
      //.padEnd(Math.min(decimalLimit, MAX_SAFE_DIGITS), '0');
    }

    if (supressTrailingZeros) {
      result += fraction;
    } else {
      result += fraction
        .replace(/0+$/, '')
        .padEnd(Math.min(decimalLimit, MAX_SAFE_DIGITS), '0');
    }

    if (sign) {
      result = '-' + result;
    }

    return result;
  }

  public getCursorMap(formatted: string): CursorMap {
    const regexp = new RegExp(this.options.thousandSeparator);
    const cursorMap: CursorMap = [];

    let index = formatted.length;
    let cursor = formatted.length;
    let skip = 0;

    while (index >= 0) {
      cursorMap[index] = cursor;

      const ignoredSymbol = regexp.exec(formatted[index - 1]);
      if (ignoredSymbol) {
        ++skip;
      } else {
        cursor = cursor - skip - 1;
        skip = 0;
      }
      --index;
    }
    return cursorMap;
  }

  private isValidString(value: string): boolean {
    const unformattedValue = this.unformatString(value);
    const destructed = this.destructString(unformattedValue);

    if (!destructed) {
      return false;
    }
    const { sign, integer, delimiter, fraction } = destructed;
    const { unsigned, precision: decimalDigits } = this.options;

    if (unsigned && sign) {
      return false;
    }

    if (decimalDigits === 0 && delimiter) {
      return false;
    }
    const integerDigits = integer === '0' ? 0 : integer.length;
    const fractionDigits = fraction.replace(/0+$/, '').length;

    if (!isNullable(decimalDigits) && fractionDigits > decimalDigits) {
      return false;
    }

    return integerDigits + fractionDigits <= 18; //TODO Максимальное безопасное число символов;
  }

  public extractValid(value: string): string {
    const unformattedValue = this.unformatString(value).replace('..', '.');
    const match = /[-.\d]+/.exec(unformattedValue);

    if (!match) {
      return '';
    }

    const token = match[0].substring(0, 17); //TODO Максимальное число символов

    for (let i = token.length; i >= 0; --i) {
      const result = token.substring(0, i);
      if (this.isValidString(result)) {
        return result;
      }
    }

    return '';
  }

  private destructString(value: string) {
    const match = /^(-)?(\d*)?(\.)?(\d*)?$/.exec(value);
    if (!match) {
      return null;
    }
    const [, sign = '', integer = '', delimiter = '', fraction = ''] = match;
    return { sign, integer, delimiter, fraction };
  }
}
