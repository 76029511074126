import { Component, Inject, OnInit } from '@angular/core';
import { BaseListComponent } from "@topseller/common/base-list";
import { BatchActionsService } from "../../services";
import {
  ProductsBatchActionsService
} from "../../../pages/products-services/product-list/products-batch-actions-service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { HubEntity, PRODUCT_LIST_NAV_TREE_CONFIG } from "../../../data";
import { TableHeader } from "@topseller/ui";
import { productHeaders } from "../../../pages/products-services/product-list/product-list.mock";
import { filter, Observable, of, switchMap } from "rxjs";
import { map } from "rxjs/operators";
import { HubInterfaceTable } from "@topseller/core";
import { NAV_TREE_CONFIG } from "../nav-tree";
import { FromStoreTableHeaderService } from "@topseller/common";
import { SelectedProductsService } from "./selectedProducts.service";
import { counterTableHeader, SelectedProductLine } from "./catalog.data";

type CatalogData = {
  products: SelectedProductLine[];
}

@Component({
  selector: 'ts-selection-of-goods-from-catalog',
  providers: [
    {provide: BaseListComponent, useExisting: SelectionOfGoodsFromCatalogComponent},
    {provide: BatchActionsService, useClass: ProductsBatchActionsService},
    {provide: NAV_TREE_CONFIG, useValue: PRODUCT_LIST_NAV_TREE_CONFIG},
    SelectedProductsService
  ],
  templateUrl: './selection-of-goods-from-catalog.component.html',
  styleUrls: ['./selection-of-goods-from-catalog.component.scss'],
})
export class SelectionOfGoodsFromCatalogComponent implements OnInit {
  mode: 'catalog' | 'selected' = 'catalog';
  displayableTableHeaders$!: Observable<TableHeader[]>;
  selectedTableHeaders$!: Observable<TableHeader[]>;
  tableIdentifier = HubInterfaceTable[HubEntity.PRODUCT]

  selectedProducts: SelectedProductLine[] = [];
  selectedProducts$: Observable<SelectedProductLine[]>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: CatalogData,
              private dialog: MatDialogRef<SelectionOfGoodsFromCatalogComponent>,
              private hubTableHeaderService: FromStoreTableHeaderService,
              private selectedProductsService: SelectedProductsService) {
    this.selectedProducts = data.products;
    selectedProductsService.setProducts(this.selectedProducts);
    this.selectedProducts$ = this.selectedProductsService.selectedProducts$;
  }

  ngOnInit() {
    this.configureProductsTableHeaders();
  }

  public viewCatalog() {
    this.mode = 'catalog';
  }

  public viewSelected() {
    this.mode = 'selected';
  }

  close() {
    this.dialog.close();
  }

  configureProductsTableHeaders() {
    const products = this.selectedProducts.map(x => x.product);
    this.displayableTableHeaders$ = this.hubTableHeaderService.getTableHeaders(this.tableIdentifier, productHeaders).pipe(
      map(headers => [counterTableHeader, ...headers]),
      switchMap(baseHeaders => products ? of(products).pipe(
        map(products => {
          const headers = [...baseHeaders]; // Клонируем массив, чтобы не мутировать оригинал
          const changerHeader = headers.pop();
          const pricesHeaders: TableHeader[] = [];
          for (const product of products) {
            if (product.productPriceGroups && product.productPriceGroups.length > 0) {
              for (const priceGroup of product.productPriceGroups)
                if (pricesHeaders.findIndex(x => x.key === priceGroup.id) < 0) {
                  pricesHeaders.push({
                    key: priceGroup.id!,
                    isResizable: false,
                    label: priceGroup.name,
                    width: '200',
                    type: 'priceGroup'
                  });
                }
            }
          }
          headers.push(...pricesHeaders);
          if (changerHeader) headers.push(changerHeader);
          return headers;
        })
      ) : of(baseHeaders))
    );

    const displayableSelectedHeaders = ['counter','name','identifiers'];
    // для хэдеров отображения на вкладке выбранного, удалим фильтры, чтобы не отображались в ячейках.
    this.selectedTableHeaders$ = this.displayableTableHeaders$.pipe(
      map(headers=> headers.filter(x=>displayableSelectedHeaders.includes(x.key))),
      map(headers => [...headers.map(x => {
        return {...x, filter: undefined, isSortable: false, isResizable: false}
      })])
    )
  }

  public addSelected() {
    const selectedProducts = this.selectedProductsService.getProducts().filter(x => x.quantity > 0);
    this.dialog.close(selectedProducts);
  }

}
