import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';

import { NavLink } from '@topseller/common';
import { Store } from '@ngrx/store';
import {
  ROLES,
  setCurrentProductInfo,
  STAFF_VIEW_ROLES,
} from '@topseller/core';

@Component({
  selector: 'ts-identity',
  templateUrl: './identity.component.html',
  styleUrls: ['./identity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class IdentityComponent {
  public links: NavLink[] = [
    {
      title: 'Профиль',
      path: '/topsellerid/profile',
      iconName: 'users-profile-circle',
    },
    {
      title: 'Подписки',
      path: '/topsellerid/subscription',
      iconName: 'money-wallet',
    },
    // {
    //   title: 'Новости',
    //   path: '/topsellerid/news',
    //   imgPath: '/assets/img/hub/nav-icons/news.svg',
    // },
    {
      title: 'Аккаунты маркетплейсов',
      path: '/topsellerid/accounts',
      iconName: 'shop-shop',
      requiredRole: ROLES.ROLE_MARKETPLACE_ACCOUNT_VIEW,
    },
    {
      title: 'Сотрудники и отделы',
      path: '/topsellerid/staff',
      iconName: 'users-people',
      requiredRole: STAFF_VIEW_ROLES,
      sub: [
        {
          title: 'Сотрудники',
          path: '/topsellerid/staff/employee',
          requiredRole: ROLES.ROLE_EMPLOYEE_VIEW,
        },
        {
          title: 'Отделы',
          path: '/topsellerid/staff/departments',
          requiredRole: ROLES.ROLE_DEPARTMENT_VIEW,
        },
      ],
    },
    // {
    //   title: 'Миграции',
    //   path: '/topsellerid/migrations',
    // },
  ];

  constructor(private store: Store) {
    this.store.dispatch(
      setCurrentProductInfo({ currentProductInfo: undefined })
    );
  }
}
