import { createReducer, on } from '@ngrx/store';

import { Account, Employee, User, AccountSelectResponseItems } from '../../model';
import {
  setAccountSelectLogin,
  logout,
  setActive,
  setImage,
  setShowHelpOnStart,
  setWasShownHelpOnStart,
  setAuthTokenLogin,
  setAuthRestrictions,
  setSettingsTableColumns, setIndicatorsSettings
} from './actions';

export interface UserState {
  isLoggedIn: boolean | undefined;
  isSignedUp: boolean | undefined;
  wasShownHelpOnStart?: boolean | undefined;
  user?: User;
  items?: AccountSelectResponseItems[];
  employee?: Employee;
  account?: Account;
  settings?: { [key: string]: string | boolean | number };
  restrictions?: Array<string>;
  message?: string,
  token?: string,
  refreshToken?: string,
}

export const initialState: UserState = {
  isLoggedIn: false,
  isSignedUp: false,
  user: {} as User,
};

export const authReducer = createReducer(
  initialState,

  on(setAccountSelectLogin, (state, { authData }) => {
    const { items, token, refreshToken, user } = authData;
    return {
      ...state,
      user,
      items,
      token,
      refreshToken,
      isLoggedIn: true,
      isSignedUp: authData.user?.isActive,
    };
  }),
  on(setActive, (state, { isSignedUp }) => ({ ...state, isSignedUp })),
  on(logout, () => ({ ...initialState })),
  on(setImage, (state, { image }) => (
    {
      ...state,
      user: {
        ...state.user,
        image
      }
    }
  )),
  on(
    setSettingsTableColumns, (state, { settings }) => ({
      ...state,
      settings: {
        ...state.settings,
        ...settings
      }
    })),
  on(setShowHelpOnStart, (state, { showHelpOnStart }) => (
    {
      ...state,
      settings: {
        ...state.settings,
        showHelpOnStart
      }
    }
  )),
  on(setWasShownHelpOnStart, (state, { wasShownHelpOnStart }) => (
    {
      ...state,
      wasShownHelpOnStart
    }
  )),
  on(setAuthTokenLogin, (state, { authData }) => {
    const { account, employee, token, refreshToken, settings, user } = authData;
    return {
      ...state,
      account,
      employee,
      token,
      refreshToken,
      settings,
      user,
      isLoggedIn: true,
      isSignedUp: authData.user?.isActive,
    };
  }),
  on(setAuthRestrictions, (state, { authData }) => {
    const { account, employee, settings, user } = authData;
    return {
      ...state,
      account,
      employee,
      settings,
      user,
      isLoggedIn: true,
      isSignedUp: authData.user?.isActive,
    };
  }),
  on(setIndicatorsSettings, (state, { settings }) => {
    return {
      ...state,
      settings: {
        ...state.settings,
        hub_interface_dashboard_indicators: settings
      }
    }
  })


)
