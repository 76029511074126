import { Injectable } from "@angular/core";
import { BatchActionsService } from "../../../common/services";
import { TemplateRequest } from "../../../data/model/templateRequest";
import { ProductService } from "../../../data";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class ProductsBatchActionsService extends BatchActionsService{
  constructor(protected productService: ProductService,toastrService: ToastrService ) {
    super(toastrService);
  }

  protected apiRequest = (request: TemplateRequest) => this.productService.template(request);

}
