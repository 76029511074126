import { EntityRecord } from "./entity-record";

export interface TsMigration extends EntityRecord {
  createdAt: string;
  startedAt: string;
  finishedAt: string;
  status: MigrationStatus;
  countSuccess: number;
  countFailed: number;
}

export interface SubTaskResponse {
  subTasks: MigrationSubTask[];
}

export interface MigrationSubTask {
  name: string;
  createdAt: string
  startedAt: string;
  finishedAt: string;
  isCompleted: boolean;
  status: MigrationSubTaskStatus;
  errors: MigrationError[] | null;
}

export interface ValidationError {
  message: string;
  object: string;
  value: string;
  id: string | null;
}

export interface MigrationErrorDetail {
  message: string;
  type: string;
  file: string;
  line: number;
  errors: ValidationError[];
}

export interface MigrationError {
  [key: string]: MigrationErrorDetail;
}

export enum MigrationSubTaskStatus {
  Created = 'created',
  Fail = 'fail',
  Running = 'running',
  Success = 'success',
}

export enum MigrationStatus {
  Created = 'created',
  Complete = 'complete',
  Pending = 'pending',
  Running = 'running',
}
