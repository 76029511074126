<ts-paginator *ngIf="pagination" [pagination]="pagination" size="l" />
<div class="items-count">
  Отображать строк:
  <ts-dropdown [content]="count">
    <button class="items-count--dropdown" type="button">
      {{ limit$ | async }}
    </button>
  </ts-dropdown>
  <ng-template #count>
    <ul class="items-count--list">
      <li
        class="items-count--list__item"
        *ngFor="let count of [20, 50, 100]"
        dropdownOption
        (click)="onCountChange(count)"
      >
        {{ count }}
      </li>
    </ul>
  </ng-template>
</div>
