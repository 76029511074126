import { Injectable } from "@angular/core";
import { BatchActionTemplate, TemplateRequest } from "../../data/model/templateRequest";
import { Observable } from "rxjs";
import { ToastrService } from "ngx-toastr";

export type BatchActionsConfig = {
  successMessage: string;
  postback: () => void;
}

@Injectable()
export abstract class BatchActionsService {
  protected abstract apiRequest: (request: TemplateRequest) => Observable<any>;


  protected constructor(private toastrService: ToastrService) {

  }

  public performAction(itemsId: string[], template: BatchActionTemplate, config: BatchActionsConfig): void {
    this.apiRequest({itemsId, template}).subscribe({
        next: (result: any) => {
          this.toastrService.success(`${config.successMessage ?? 'Успешно'}`);
          config.postback()
        },
        error: (err: any) => {
          this.toastrService.error(
            err?.errors?.length && err.errors[0].message
              ? err.errors[0].message
              : err?.message || 'Что-то пошло не так'
          );
        },
      }
    )
  }
}


