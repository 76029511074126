import { InjectionToken } from '@angular/core';
import { CompanyComponent } from '../pages/handbooks/company/company.component';
import { PartnerComponent } from '../pages/handbooks/partner/partner.component';
import { StorageComponent } from '../pages/handbooks/storage/storage.component';
import { ProjectItemComponent } from '../pages/handbooks/project-item/project-item.component';
import { CashflowItemComponent } from '../pages/handbooks/cashflow/cashflow-item/cashflow-item.component';
import { SourceItemComponent } from '../pages/handbooks/sources/source-item/source-item.component';
import { COMMENTS_FILES_VIEW_ROLES, ROLES } from "@topseller/core";
import { NavTreeConfig } from "../common/components/nav-tree";

export const BASE_PATH = new InjectionToken<string>('basePath');
export const COLLECTION_FORMATS = {
  csv: ',',
  tsv: '   ',
  ssv: ' ',
  pipes: '|',
};

export enum HubEntity {
  PURCHASE = 'purchase',
  ORDER = 'order',
  MOVEMENT = 'movement',
  PRODUCT = 'product',
  PAYMENT = 'payment',
  POSTING = 'posting',
  WRITE_OFF = 'writeOff',
  INVENTORY = 'inventory',
  BANK = 'bank',
  BARCODE_TYPE = 'barcodeType',
  CORRECTION = 'correction',
  COMPANY = 'company',
  PARTNER = 'partner',
  WAREHOUSE = 'warehouse',
  PROJECT = 'project',
  CASHFLOW = 'cashflow',
  SOURCE = 'source',
  REPORT_WAREHOUSE = 'reportWarehouse',
  REPORT_PRODUCT = 'reportProduct',
  REPORT_PROFIT = 'reportProfit',
  REPORT_TURNOVER = 'reportTurnover',
  FILE = 'file',
  BRAND = 'brand',
  SETTING = 'setting',
  DEMAND = 'demand',
  RECALL = 'recall',
  RECEIVE = 'receive',
  REVERT = 'revert',
  CASHFLOW_SOURCE = 'cashflowSource',
  CASHFLOW_SOURCE_GROUP = 'cashflowSourceGroup',
  COMENTARY = 'commentary',
  COUNTERPARTY = 'counterparty',
  TRASH = 'trash',
  DEPARTMENT = 'department',
  EMPLOYEE = 'employee',
  TASK = 'task'
}

export const entityClass = {
  [HubEntity.COMPANY]: CompanyComponent,
  [HubEntity.PARTNER]: PartnerComponent,
  [HubEntity.WAREHOUSE]: StorageComponent,
  [HubEntity.PROJECT]: ProjectItemComponent,
  [HubEntity.CASHFLOW]: CashflowItemComponent,
  [HubEntity.SOURCE]: SourceItemComponent,
};

export const entityNames: { [key in Partial<HubEntity> | string]?: string } = {
  [HubEntity.ORDER]: 'Заказ покупателя',
  purchase: 'Заказ поставщику',
  movement: 'Перемещение',
  payment: 'Платеж',
  posting: 'Оприходование',
  writeOff: 'Списание',
  correction: 'Корректировка',
  inventory: 'Инвентаризация',
  product: 'Товар',
  reportProduct: 'Отчет',
  reportWarehouse: 'Отчет',
  reportProfit: 'Отчет',
  warehouse: 'Склад',
  company: 'Органицзации',
  cashflow: 'Статьи',
  setting: 'Настройки',
  bank: 'Банки',
  barcodeType: 'Типы баркодов',
  brand: 'Бренды',
  cashflowSource: 'Статьи ДДС',
  cashflowSourceGroup: 'Группы статей ДДС',
  commentary: 'Комментарии',
  counterparty: 'Контрагенты',
  country: 'Страны',
  currency: 'Валюты',
  customField: 'Дополнительные поля',
  department: 'Отделы',
  employee: 'Сотрудники',
  employeeFilter: 'Фильтры сотрудников',
  filter: 'Фильтры',
  modificationAttribute: 'Атрибуты модификаций',
  organization: 'Организации',
  productGroup: 'Группы товаров',
  productMarketplacePrice: 'Цены маркетплейсов',
  productPriceGroup: 'Группы цен',
  productPriceType: 'Типы цен',
  productStock: 'Остатки товаров',
  project: 'Проекты',
  [HubEntity.RECALL]: 'Возврат от покупателя',
  [HubEntity.REVERT]: 'Возврат поставщику',
  [HubEntity.RECEIVE]: 'Приемка',
  [HubEntity.DEMAND]: 'Отгрузка',
  settings: 'Настройки',
  source: 'Статьи расходов и доходов',
  sourceGroup: 'Группы статей расходов и доходов',
  status: 'Статусы',
  tag: 'Теги',
  unit: 'Единицы измерения',
  user: 'Пользователи',
  vatType: 'Типы НДС',
  warehouseGroup: 'Группы складов',
  writeOffGroup: 'Группы списаний',
  trash: 'Корзина',
};

export interface ListControlsConfig {
  editItemRole?: string | string[];
  deleteItemsRole?: string | string[];
}

export interface ItemRolesConfig{
  auditViewRole:string;
  commentsAndFilesViewRoles: string[];
  commentsViewRole:string;
  filesViewRole:string;
  filesDeleteRole:string;
  commentsDeleteRole:string;
}

export const EditItemRolesConfig: ItemRolesConfig ={
  auditViewRole: ROLES.ROLE_AUDIT_VIEW,
  commentsAndFilesViewRoles: COMMENTS_FILES_VIEW_ROLES,
  commentsDeleteRole: ROLES.ROLE_COMMENTARY_DELETE,
  commentsViewRole: ROLES.ROLE_COMMENTARY_VIEW,
  filesDeleteRole: ROLES.ROLE_FILE_DELETE,
  filesViewRole: ROLES.ROLE_FILE_VIEW
}

export const PRODUCT_LIST_NAV_TREE_CONFIG: NavTreeConfig = {
  addItemRole: ROLES.ROLE_PRODUCT_EDIT,
  deleteItemsRole: ROLES.ROLE_PRODUCT_DELETE
}
