import { Injectable } from '@angular/core';
import { EMPTY, exhaustMap, finalize, Observable, takeUntil } from 'rxjs';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "@topseller/core";
import { ToastrService } from "ngx-toastr";

export type DeleteConfig = {
  ids: string[];
  modalTitle: string;
  modalConfirmMessage: string;
  toasterSuccessText: string;
  okBtn?: string;
  cancelBtn?: string;
  afterDeleteFn: () => void;
  deleteFn: (ids: string[]) => Observable<any>;
}

@Injectable()
export class ListItemsDeleteService {
  constructor(
    protected matDialog: MatDialog,
    protected toastrService: ToastrService,
  ) {
  }
  public delete(config: DeleteConfig): void {
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.matDialog.open(
      ConfirmDialogComponent,
      {
        data: {
          title: `${config.modalTitle}`,
          content: `${config.modalConfirmMessage}`,
          okBtn: `${config?.okBtn ?? 'Удалить'}`,
          cancelBtn: `${config?.cancelBtn ?? 'Отменить'}`
        },
        backdropClass: 'ts-modal-backdrop',
        panelClass: ['ts-modal-panel','ts-modal-panel-lg' ],
        width: '470px',
        id: 'ts-modal',
      }
    );

    dialogRef
      .afterClosed()
      .pipe(
        exhaustMap((result: boolean) => {
          if (!result) {
            return EMPTY;
          }

          return config.deleteFn(config.ids);
        })
      ).subscribe({
      next: (result: any) => {
        this.toastrService.success(`${config.toasterSuccessText ?? 'Удаление прошло успешно'}`);
        config.afterDeleteFn()
      },
      error: (err: any) => {
        this.toastrService.error(
          err?.errors?.length && err.errors[0].message
            ? err.errors[0].message
            : err?.message || 'Что-то пошло не так'
        );
      },
    });
  }
}
