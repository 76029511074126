<div class="input-counter">
  <i class="ts-icon ts-icon-actions-minus" (mousedown)="setNewValue(-1)"
     (mouseup)="stopChanging()"
     (mouseleave)="stopChanging()"></i>
  <input [ngModel]="value"
         (ngModelChange)="onModelChange($event)"
         [tsInputNumber]="{precision:0}"
         [min]="minValue"
         [max]="maxValue">
  <i class="ts-icon ts-icon-actions-Add" (mousedown)="setNewValue(1)"
     (mouseup)="stopChanging()"
     (mouseleave)="stopChanging()"></i>
</div>
