import { ChangeDetectorRef, Component, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first, take } from 'rxjs';
import { BaseItemInfoComponent } from '../../../../common/components/base-item-info.component';
import { IdentityMatcher, ListQueryFn, StringHandler, } from '../../../../common/types';
import { Source, SourceGroup, SourceService } from '../../../../data';
import { Store } from "@ngrx/store";
import { ConfirmDialogComponent, DialogService, Record, ROLES, setShowLoader } from '@topseller/core';
import { BaseInnItemComponent } from "../../../../common/components/base-inn-item.component";

@Component({
  selector: 'ts-handbooks-source-item',
  templateUrl: './source-item.component.html',
  styleUrls: ['../../company/company.component.scss'],
  providers: [{provide: BaseInnItemComponent, useExisting: SourceItemComponent}]
})
export class SourceItemComponent
  extends BaseItemInfoComponent
  implements OnInit {
  public form: FormGroup = new FormGroup({
    id: new FormControl<string | null>(null),
    name: new FormControl<string>('', [Validators.maxLength(255), Validators.required]),
    note: new FormControl<string>('', [Validators.maxLength(255)]),
    isIncome: new FormControl<boolean>(false),
    sourceGroup: new FormControl<SourceGroup | null>(null, [Validators.required]),
  });

  public id = '';
  public editItemRole = ROLES.ROLE_SOURCE_EDIT;
  public deleteItemRole = ROLES.ROLE_SOURCE_DELETE;
  override backLinkUrl = '/hub/handbooks/sources';

  constructor(
    activatedRoute: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    matDialog: MatDialog,
    changeDetectorRef: ChangeDetectorRef,
    store: Store,
    private sourceService: SourceService,
    @Optional() private dialogRef: MatDialogRef<SourceItemComponent>,
    private dialogService: DialogService
  ) {
    super(activatedRoute, toastr, router, changeDetectorRef, matDialog, store);
  }

  public get sourceGroupQueryFn(): ListQueryFn<SourceGroup> {
    return this.countryListFn;
  }

  public get isModal(): boolean {
    return this.dialogRef?.componentInstance instanceof SourceItemComponent;
  }

  ngOnInit(): void {
    this.getResolvedData(this.form, 'source');
  }

  public recordBooleanStringify: StringHandler<boolean> = (item) =>
    item ? 'Статья дохода' : 'Статья расхода';

  public recordIdentityMatcher: IdentityMatcher<Record> = (
    {id: previousId},
    {id: nextId}
  ) => previousId === nextId;

  public recordStringify: StringHandler<Record> = (item) => item.name;

  public deleteSource() {
    const data = {
      title: 'Удаление статьи расходов и доходов',
      content: 'Вы уверены, что хотите удалить статью расходов и доходов?',
      okBtn: 'Удалить'
    };
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialogService.openConfirmDialog(data);

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe({
        next: (result: boolean) => {
          this.deleteItem(
            this.sourceService,
            'deleteAppSourceDelete',
            '/hub/handbooks/sources',
            result ? 'да' : 'нет'
          );
        }
      });
  }

  public save(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.toastr.error('Форма содержит ошибки');
      return
    }
    this.store.dispatch(setShowLoader({showLoader: true}))
    const {id} = this.form.value;
    if (id === null || id === undefined) {
      this.create();
    } else {
      this.update();
    }
  }

  public cancel(): void {
    this.dialogRef.close();
  }


  private create() {
    this.sourceService
      .postAppSourceCreate(this.form.value)
      .pipe(take(1))
      .subscribe({
        next: (res: Source) => {
          this.toastr.success('Статья успешно создана');
          this.store.dispatch(setShowLoader({showLoader: false}))
          if (this.isModal) {
            this.dialogRef.close(res);
          } else {
            this.form.markAsPristine();

            this.navigateByUrl(`/hub/handbooks/sources/${res.id}`);
          }
        },
        error: (err: any) => {
          this.creationState$.next('error');
          this.store.dispatch(setShowLoader({showLoader: false}))

          this.toastr.error(
            err?.errors?.length && err.errors[0].message
              ? err.errors[0].message
              : err?.message || 'Что-то пошло не так'
          );
        },
      });
  }

  private update(): void {
    this.store.dispatch(setShowLoader({showLoader: true}))
    this.updateItem(
      this.form,
      this.sourceService,
      'patchAppSourceUpdate',
      'Статья'
    );
  }

  private countryListFn: ListQueryFn<SourceGroup> = ({
                                                       limit = -1,
                                                       offset = 0,
                                                     }) => this.sourceService.getSourceGroups(limit, offset);
}
