import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {TableHeader} from "@topseller/ui";
import {ProductCatalogItem} from "../../../../../data";
import {SelectedProductsService} from "../../selectedProducts.service";
import { MAX_ITEMS_COUNT } from "@topseller/core";
import { SelectedProductLine } from "../../catalog.data";

@Component({
  selector: 'ts-products-catalog-table',
  templateUrl: './products-catalog-table.component.html',
  styleUrls: ['./products-catalog-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductsCatalogTableComponent {
  @Input() tableHeaders: TableHeader[] = [];
  @Input() selectedProducts: SelectedProductLine[] = [];
  maxItemsCount = MAX_ITEMS_COUNT;

  constructor(private selectedProductsService: SelectedProductsService) {
  }

  get priceHeaders(): TableHeader[] {
    return this.tableHeaders?.filter(x => x.type === 'priceGroup') ?? [];
  }

  changeProductsCount(count: number, product: ProductCatalogItem) {
    if (count > 0) {
      const existingProduct = this.selectedProducts.find(p => p.product.id === product.id);
      if (existingProduct) {
        // Обновляем количество, если продукт уже был в списке
        existingProduct.quantity = count;
      } else {
        // Добавляем продукт в список, если его там не было
        this.selectedProducts.push({
          id: product.id,
          product: product,
          quantity: count
        });
      }
    } else {
      // Удаляем продукт из списка, если количество 0 или меньше
      this.selectedProducts = this.selectedProducts.filter(p => p.product.id !== product.id);
    }
    this.selectedProductsService.setProducts(this.selectedProducts);
  }

  getQuantity(product: ProductCatalogItem): number {
    const selectedProduct = this.selectedProducts.find(p => p.product.id === product.id);
    if (!selectedProduct) {
      return 0;
    }
    return selectedProduct.quantity;
  }
}
