import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AuthService, AUTH_TOKEN_NAME, LocalStorageService,
  REFRESH_TOKEN_NAME
} from '@topseller/core';
import { catchError, Observable, Subject, throwError } from 'rxjs';
import { finalize, switchMap, take } from 'rxjs/operators';

const guardedRoute = (url: string): boolean => {
  return !/\/security\/(login|refresh|signup|confirm|request-code|confirm-email)$/.test(
    url
  );
};

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshInProgress = false;
  private tokenSubject: Subject<string | undefined> = new Subject();

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly authService: AuthService,
    private store: Store<any>
  ) { }

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const authToken = this.localStorageService.get(AUTH_TOKEN_NAME);
    const authReq = authToken
      ? this.addTokenHeader(request, authToken)
      : request;

    return next.handle(authReq).pipe(
      catchError((response: HttpErrorResponse) => {
        if (
          response instanceof HttpErrorResponse &&
          (response.status === 401 || response.status === 403) &&
          guardedRoute(response.url!)
        ) {
          return this.handleRefreshToken(authReq, next);
        }
        return throwError(() => response.error);
      })
    );
  }

  private handleRefreshToken(
    request: HttpRequest<unknown>,
    next: HttpHandler,
    retry = false
  ) {
    const refreshToken = this.localStorageService.get(REFRESH_TOKEN_NAME);
    const refreshError = throwError(() => {
      new Error();
    });

    if (this.isRefreshInProgress) {
      return this.tokenSubject.pipe(
        take(1),
        switchMap((authToken: string | undefined) => {
          if (retry) {
            const req = authToken
              ? this.addTokenHeader(request, authToken)
              : request;
            return next.handle(req);
          }

          return authToken
            ? next.handle(this.addTokenHeader(request, authToken))
            : refreshError;
        })
      );
    }

    if (!refreshToken) {
      return retry ? next.handle(request) : refreshError;
    }

    this.isRefreshInProgress = true;

    return this.authService.refresh(refreshToken).pipe(
      switchMap((accessToken: string) => {
        this.tokenSubject.next(accessToken);
        return next.handle(this.addTokenHeader(request, accessToken));
      }),
      catchError((error: any) => {
        this.tokenSubject.next(undefined);
        this.localStorageService.remove(REFRESH_TOKEN_NAME);
        if (retry) {
          return next.handle(request);
        }
        return throwError(() => {
          new Error(error);
        });
      }),
      finalize(() => (this.isRefreshInProgress = false))
    );
  }

  private addTokenHeader(
    request: HttpRequest<unknown>,
    token: string
  ): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + token,
      },
    });
  }
}
